<template>
  <div>
    <div class="mt-0 pt-0">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData.need_to_show_message"
      >
        <span v-html="exampleFileData.message"></span>
      </v-alert>
    </div>
    <div class="px-6 row">
      <div class="col-12" :class="{ 'col-sm-6': !isClient }" v-if="!isClient">
        <v-autocomplete
          clearable
          dense
          outlined
          v-model="formData.client"
          :items="clients"
          item-text="text"
          item-value="index"
          placeholder="Choose client"
          :error-messages="clientErrors"
          @input="$v.formData.client.$touch()"
          @blur="$v.formData.client.$touch()"
        ></v-autocomplete>
      </div>
      <div class="col-12" :class="{ 'col-sm-6': !isClient }">
        <v-select
          dense
          outlined
          v-model="formData.order_type"
          :items="order_types"
          item-text="text"
          item-value="index"
          placeholder="Order type"
          :error-messages="order_typeErrors"
          @input="$v.formData.order_type.$touch()"
          @blur="$v.formData.order_type.$touch()"
        ></v-select>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-md-9">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            label="File input"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          ></v-file-input>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-center">
          <v-btn
            class="primary--text"
            outlined
            text
            :href="exampleFileData.url"
          >
            Download Template
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        Clear
      </button>
      <button
        type="submit"
        class="btn btn-info px-5 py-3 ls1"
        @click="submitForm"
      >
        Upload
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "OrderForm",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        client: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        order_type: { required },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    formData: {
      client: null,
      file: null,
      order_type: null,
    },
  }),
  beforeMount() {
    this.formData.order_type = this.order_types[0].index;
  },
  computed: {
    isClient() {
      return !(
        this.$store.getters.currentUser.data.user_type == "admin" ||
        this.$store.getters.currentUser.data.user_type == "aggregator"
      );
    },
    clients: function () {
      return this.importTypeData.data.customers;
    },
    order_types() {
      return this.importTypeData.data.order_types;
    },
    // TODO: update example file according to order type
    exampleFileData: function () {
      let sample;
      try {
        sample = this.importTypeData.data.files.filter(
          (file) => file.type == this.formData.order_type
        )[0];
      } catch {
        sample = null;
      }
      return sample;
    },
    clientErrors() {
      return this.handleFormValidation("client", this);
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    order_typeErrors() {
      return this.handleFormValidation("order_type", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.formData.order_type);
      if (!this.isClient) {
        data.append("customer_id", this.formData.client);
      }
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        client: null,
        file: null,
        order_type: this.order_types[0].index,
      };
    },
  },
};
</script>

<style></style>
