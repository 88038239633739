<template>
  <div class="ma-0 pa-0 row">
    <div class="col-12 col-sm-4">
      <ImportHeaderCard
        icon="mdi-check-bold"
        title="Success"
        :value="success"
        theme="success"
      />
    </div>
    <div class="col-12 col-sm-4">
      <ImportHeaderCard
        icon="mdi-exclamation"
        title="Error"
        :value="error"
        theme="error"
      />
    </div>
    <div class="col-12 col-sm-4">
      <ImportHeaderCard
        icon="mdi-upload"
        title="Total"
        :value="total"
        theme="info"
      />
    </div>
  </div>
</template>

<script>
import ImportHeaderCard from "./ImportHeaderCard.vue";
export default {
  name: "ImportHeader",
  components: { ImportHeaderCard },
  props: {
    total: {
      required: true,
      type: Number,
    },
    error: {
      required: true,
      type: Number,
    },
    success: {
      required: true,
      type: Number,
    },
  },
};
</script>
