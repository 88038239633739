<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->
      <!--          header-->
      <!-- <template
        v-for="head in headers"
        v-slot:[`header.`+head.value]="{ header }"
      >
        <div class="text-left" :class="header.class" v-bind:key="head.value">
          {{ header.text }}
        </div>
      </template> -->
      <!--          end of header-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-5"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <!--            <div-->
            <!--              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"-->
            <!--              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"-->
            <!--              v-if="permissionChecker('create')"-->
            <!--            >-->
            <!--              <button-->
            <!--                @click.prevent="showAddItemModal"-->
            <!--                class="btn btn&#45;&#45;export-filter"-->
            <!--              >-->
            <!--                <span class="svg-icon">-->
            <!--                  <v-icon size="18">mdi-plus-box-outline</v-icon>-->
            <!--                </span>-->
            <!--                Add {{ table_properties.title }}-->
            <!--              </button>-->
            <!--              <AddItem-->
            <!--                :pageLoader="pageLoad"-->
            <!--                :refresher="updateTableContent"-->
            <!--                ref="addItem"-->
            <!--              ></AddItem>-->
            <!--            </div>-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('upload')"
            >
              <button
                class="btn btn--print-upload px-4"
                @click.prevent="showUploadModal"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </span>
                Upload
              </button>
              <UploadSelect
                ref="upload"
                :types="uploadTypes"
                :updateTableContent="updateTableContent"
                :pageLoad="pageLoad"
                :uploadTypeData="uploadTypeData"
              ></UploadSelect>
            </div>

            <!--begin::Dropdown-->

            <!--end::Button-->
          </div>
        </div>
        <!-- filter container -->
        <ExportSidebar
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!-- filter container end -->
        <Others ref="otherData"></Others>
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ColumnSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ColumnSelector>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_FILTERS_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/fileUpload.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ColumnSelector from "@/own/components/datatable/ComponentSelector.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";
import Others from "@/own/components/fulfillment/asn/Others.vue";
import UploadSelect from "@/own/components/fulfillment/fileUpload/UploadSelect.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    Others,
    UploadSelect,
    ColumnSelector,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    offset: true,
    columnsModal: false,

    allSelected: false,
    uploadType: null,
  }),
  beforeMount() {
    if (this.$route.query.type) {
      if (!Array.isArray(this.$route.query.type))
        this.uploadType = this.$route.query.type;
      else this.uploadType = this.$route.query.type[0];
    }
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  beforeCreate() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  beforeDestroy() {
    this.resetFilterValues();
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.resetFilterValues();
    // this.$store
    //   .dispatch(UPDATE_TABLE_DATA, {
    //     type: [...this.$route.query.type.slice(",")],
    //   })
    //   .then(() => {});
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    uploadTypeData() {
      return this.uploadType;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getFILEUPTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getFILEUPTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getFILEUPTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getFILEUPTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getFILEUPTableFilters;
    },
    table_permissions: function () {
      if (this.$store.getters.getFILEUPTablePermissions) {
        return this.$store.getters.getFILEUPTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getFILEUPTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getFILEUPTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getFILEUPTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            data: this.$store.getters.getFILEUPTableState,
          });
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            data: this.$store.getters.getFILEUPTableState,
          });
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getFILEUPTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          data: this.$store.getters.getFILEUPTableState,
        });
      },
    },
    headers: function () {
      let headers = this.$store.getters.getFILEUPTableHeaders.filter(
        (col) => col.visible === true
      );
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      try {
        return this.$store.getters.getFILEUPTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch {
        return [];
      }
    },
    items: function () {
      if (this.$store.getters.getFILEUPTableData) {
        return this.$store.getters.getFILEUPTableData.data;
      }
      return undefined;
    },
    hasSelected: function () {
      return this.selected.length > 0;
    },
    uploadTypes() {
      return this.$store.getters.getFILEUPTypes;
    },
  },
  methods: {
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getFILEUPTableState };
      state.page = 1;
      if (this.$route.query.type) {
        state["type"] = this.$route.query.type.slice(",");
      }
      this.$store
        .dispatch(UPDATE_TABLE_DATA, { data: state })
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
      this.$store.commit(SET_FILTERS_DATA, this.men);
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.men = [];
      this.queryObj = {};
      // this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store.commit(SET_TABLE_PAGE, 1);
      let state = { ...this.$store.getters.getFILEUPTableState };
      if (this.$route.query.type) {
        state["type"] = this.$route.query.type.slice(",");
      }
      this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          data: state,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    // exports
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          filters: this.$store.getters.getFILEUPTableState.filters,
        })
        .then(() => {
          const link = document.createElement("a");
          link.href = this.$store.getters.getFILEUPExportUrl;
          link.download = "Export.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
        });
    },
    // exports end
    showUploadModal() {
      this.$refs.upload.toggleUploadModal(this.uploadType);
    },
    showDetail(val) {
      let id = val.path.filter((node) => {
        if (node.localName == "tr") {
          return node;
        }
      })[0].childNodes[1].childNodes[0].id;
      this.loadDetailData(id);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { asn_id: id };
      ApiService.post("/account/uploads/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          // this.$refs.otherData.$props.isLoaded = true;
          // this.$refs.otherData.$props.other = response.data.asn;
          this.$refs.otherData.setData(response.data.asn);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    // showAddItemModal() {
    //   this.$refs.addItem.toggleModal();
    // },
    updateTableContent() {
      this.pageLoad(true);
      let updata = { data: this.$store.getters.getFILEUPTableState };
      if (this.$route.query.type) {
        updata["type"] = this.$route.query.type.slice(",");
      }
      this.$store.dispatch(UPDATE_TABLE_DATA, updata).then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
  },
};
</script>
