<template>
  <v-dialog v-model="dialog" persistent max-width="1000">
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Choose Data Type</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="select-main pa-2">
          <v-card
            v-for="(type, i) in types"
            :key="i"
            class="select-item ma-4 py-8 text-center col-12 col-sm-6 col-md-4 col-lg-3"
            @click="() => selectForm(type)"
          >
            <img class="select-img" :src="type.image" :alt="type.text" />
            <div class="text-h5 font-weight-bold pt-6">{{ type.text }}</div>
          </v-card>
        </div>
      </div>
    </div>
    <ImportData
      ref="dataImporter"
      :import-type-form="getImportForm"
      :importTypeRelatedData="getImportData"
      :updateTableContent="updateTableContent"
    >
    </ImportData>
  </v-dialog>
</template>

<script>
import ImportData from "./ImportData.vue";
import OrderForm from "./forms/OrderForm.vue";
import CodForm from "./forms/CodForm.vue";
import OrderStatusForm from "./forms/OrderStatusForm.vue";
import SkuBundleForm from "./forms/SkuBundleForm.vue";
import SkuForm from "./forms/SkuForm.vue";
import SkuIntegrationForm from "./forms/SkuIntegrationForm.vue";
import AsnForm from "./forms/AsnForm.vue";
import SkuUpdateForm from "./forms/SkuUpdateForm.vue";
import AlterSkuForm from "./forms/AlterSkuForm.vue";
import ProductForm from "./forms/ProductForm.vue";
import ProductUpdateForm from "./forms/ProductUpdateForm.vue";
import CourierStatusForm from "./forms/CourierStatusForm.vue";
import AsnSkuForm from "./forms/AsnSkuForm.vue";
import LocationMappingsForm from "./forms/LocationMappingsForm.vue";
export default {
  name: "UploadSelect",
  components: {
    ImportData,
  },
  props: {
    types: {
      required: true,
      type: Array,
    },
    updateTableContent: {
      required: true,
    },
    pageLoad: {
      required: true,
    },
    uploadTypeData: {
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    uploadComponent: null,
    importForm: null,
    importData: null,
  }),
  mounted() {},
  beforeMount() {},
  computed: {
    getImportForm() {
      return this.importForm;
    },
    getImportData() {
      return this.importData;
    },
  },
  methods: {
    toggleUploadModal(uploadTypeData) {
      this.dialog = !this.dialog;
      if (uploadTypeData) {
        this.$nextTick(() => {
          const upload = this.types.find(
            (type) => type.type === uploadTypeData
          );
          this.selectForm(upload);
        });
      }
    },
    selectForm(type) {
      switch (type.type) {
        case "order":
          this.importForm = OrderForm;
          break;
        case "order_status":
          this.importForm = OrderStatusForm;
          break;
        case "cod":
          this.importForm = CodForm;
          break;
        case "sku":
          this.importForm = SkuForm;
          break;
        case "sku_bundle":
          this.importForm = SkuBundleForm;
          break;
        case "sku_integration":
          this.importForm = SkuIntegrationForm;
          break;
        case "sku_update":
          this.importForm = SkuUpdateForm;
          break;
        case "sku_code":
          this.importForm = AlterSkuForm;
          break;
        case "asn":
          this.importForm = AsnForm;
          break;
        case "fulfillment_product":
          this.importForm = ProductForm;
          break;
        case "fulfillment_product_update":
          this.importForm = ProductUpdateForm;
          break;
        case "carrier_status":
          this.importForm = CourierStatusForm;
          break;
        case "pickup":
          this.importForm = ProductForm;
          break;
        case "asn_sku":
          this.importForm = AsnSkuForm;
          break;
        case "carrier_location_mappings":
          this.importForm = LocationMappingsForm;
      }
      this.importData = type;
      this.$nextTick(() => {
        this.$refs.dataImporter.toggleModal();
      });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.select-img {
  width: 6rem;
  height: 6rem;
}
.select-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}
</style>
